<template>
    <a-layout>
        <a-layout-sider>
            <div class="logo">
                管理平台
            </div>

            <custom-menu :menu="menu" @click="goRouter"/>
        </a-layout-sider>

        <a-layout>
            <a-layout-header style="background: #fff;">
                <a-row style="width: 100%">
                    <a-col :offset="8" :span="4" style="float: right">
                        <a-space align="center">
                            <a-button icon="question-circle" style="color: black" type="link"
                                      @click="$notification['warning']({ message: '联系电话：18351775621'})">
                                帮助
                            </a-button>

                            <!-- <a-avatar style="backgroundColor:#87d068">
                              {{ regionName && regionName[0] }}
                            </a-avatar>
                            <span>
                              {{ regionName }}
                            </span> -->
                        </a-space>
                    </a-col>
                </a-row>

            </a-layout-header>

            <a-layout-content
                    :style="{ padding: '15px',  boxSizing: 'border-box' }">
                <transition>
                    <router-view/>
                </transition>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>
<script>
import {removeToken} from '@/utils/auth'
import CustomMenu from './components/customMenu'
import {mapState} from "vuex";

export default {
    name: 'layout',
    components: {CustomMenu},
    data() {
        return {
            menu: [],
            currentMenuKey: 0,
            currentSubKey: 0,
        };
    },
    async mounted() {
        await this.getRouters()
    },
    methods: {
        exitLogin() {
            removeToken()
            this.$router.push({path: '/login'})
        },

        goRouter(index, subIndex) {
            //update
            this.currentSubKey = subIndex
            this.currentMenuKey = index
            let path = '/user/' + this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
            this.$router.push({path: path})
            localStorage.setItem('menuKey', this.currentMenuKey)
            localStorage.setItem('subKey', this.currentSubKey)
            localStorage.setItem('path', path)
        },

        goRouter1(index, subIndex) {
            console.log(index, subIndex)
        },

        async getRouters() {
            let menu = this.$store.getters.getRouters[6].children
            //update
            menu.forEach(item => {
                if (item.children !== undefined) {
                    this.menu.push(item)
                }
            })
            this.currentMenuKey = localStorage.getItem('menuKey') ? localStorage.getItem('menuKey') : 0
            this.currentSubKey = localStorage.getItem('subKey') ? localStorage.getItem('subKey') : 0
            let path = this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
            //详情页刷新保存当前带参路由
            if (this.$route.fullPath) {
                path = this.$route.fullPath
            }
            //
            this.$router.push({path: path})
            localStorage.setItem('menuKey', this.currentMenuKey)
            localStorage.setItem('subKey', this.currentSubKey)
            localStorage.setItem('path', path)
        },
    },
    computed: mapState({
        regionName: state => state.user.module.regionName
    })
};
</script>

<style lang="scss">
.ant-layout {
  height: 100%;

  .ant-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.logo {
  height: 32px;
  margin: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.v-enter, .v-leave-to {
  opacity: 0;
  transition: opacity .3s;
}

.v-enter-active, .v-leave-active {
  transition: opacity .3s;
}

</style>
